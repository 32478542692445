import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 28,
    title: "Integrazione Facile di Immagini e Video a 360° con React 360",
    desc: "",
    img: "/blog-image/react360.png",
    page: "blog/react-360",
    data: "13 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "La realtà virtuale e l'esperienza a 360 gradi stanno diventando sempre più popolari nell'ambito dello sviluppo delle applicazioni web.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        La realtà virtuale (VR) e l'esperienza a
                                        360 gradi stanno diventando sempre più
                                        popolari nell'ambito dello sviluppo
                                        delle applicazioni web. React 360 è una
                                        libreria che semplifica l'integrazione
                                        di contenuti immersivi a 360 gradi nelle
                                        tue applicazioni React. In questo
                                        articolo, esploreremo come puoi
                                        facilmente integrare immagini e video a
                                        360 gradi nelle tue app utilizzando
                                        React 360.
                                    </p>

                                    <h3>Cos'è React 360?</h3>
                                    <p>
                                        React 360 è una libreria per React che
                                        ti consente di creare esperienze VR e a
                                        360 gradi direttamente nel browser. È un
                                        progetto open-source sviluppato da
                                        Facebook e offre un'interfaccia
                                        familiare per gli sviluppatori React.
                                    </p>
                                    <h3>Integrazione di Immagini a 360°</h3>
                                    <p>
                                        Per integrare un'immagine a 360 gradi in
                                        una tua app React 360, puoi seguire
                                        questi passaggi:
                                    </p>

                                    <ol>
                                        <li>
                                            <p>
                                                <b>
                                                    Installazione di React 360
                                                </b>
                                                : Assicurati di avere Node.js e
                                                npm installati, quindi crea un
                                                nuovo progetto React 360.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx react-360-cli init NomeProgetto `}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <p>
                                                <b>
                                                    Aggiunta dell'immagine a 360
                                                    gradi
                                                </b>
                                                : Copia l'immagine a 360 gradi
                                                (spesso in formato
                                                equirettangolare) nella cartella{" "}
                                                <b>static_assets</b> del tuo
                                                progetto.
                                            </p>
                                        </li>

                                        <li>
                                            <p>
                                                <b>
                                                    Creazione del componente VR
                                                </b>
                                                : Crea un nuovo componente React
                                                che utilizzerà l'immagine a 360
                                                gradi come sfondo per la scena
                                                VR. Puoi utilizzare il
                                                componente Pano di React 360 per
                                                questo scopo.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`import React from 'react';
import { AppRegistry, View, Pano } from 'react-360';

class App extends React.Component {
  render() {
    return (
      <View>
        <Pano source={asset('360_image.jpg')} />
      </View>
    );
  }
}

AppRegistry.registerComponent('App', () => App); `}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <p>
                                                <b>Lancio dell'app</b>: Lanciare
                                                l'app React 360 per visualizzare
                                                l'immagine a 360 gradi nel
                                                browser.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx react-360-cli run`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ol>

                                    <h3>Integrazione di Video a 360°</h3>

                                    <p>
                                        L'integrazione di video a 360 gradi è
                                        simile all'integrazione di immagini, ma
                                        utilizza il componente VideoPano per la
                                        riproduzione del video. Ecco come farlo:
                                    </p>

                                    <ol>
                                        <li>
                                            <p>
                                                <b>Aggiunta del video a 360°</b>
                                                : Copia il video a 360 gradi
                                                nella cartella static_assets del
                                                tuo progetto.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>
                                                    Creazione del componente VR
                                                    con video
                                                </b>
                                                : Modifica il componente VR per
                                                utilizzare il componente{" "}
                                                <b>VideoPano</b> anziché{" "}
                                                <b>Pano</b>.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`import React from 'react';
import { AppRegistry, View, VideoPano } from 'react-360';

class App extends React.Component {
  render() {
    return (
      <View>
        <VideoPano source={{ uri: 'video_url.mp4' }} />
      </View>
    );
  }
}

AppRegistry.registerComponent('App', () => App);
`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <p className="pt-4">
                                                <b>Lancio dell'app</b>: Lanciare
                                                nuovamente l'app React 360 per
                                                visualizzare il video a 360
                                                gradi nel browser.
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx react-360-cli run `}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                    </ol>

                                    <p className="pt-4">
                                        Come vedi React 360 semplifica
                                        notevolmente l'integrazione di contenuti
                                        immersivi a 360 gradi nelle tue
                                        applicazioni web React. Sia che tu stia
                                        cercando di creare tour virtuali,
                                        esperienze VR o semplicemente arricchire
                                        il tuo sito web con contenuti a 360
                                        gradi, React 360 è lo strumento principe
                                        da considerare. E se hai bisogno di un
                                        esperto per il tuo progetto puoi sempre
                                        contare su di noi. Buon coding.
                                    </p>
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>
                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
